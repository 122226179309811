.heroContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1320px;
  overflow: hidden;
}

.startNowLink {
  text-decoration: none;
  color: white;
}

.startNowLink:hover {
  color: white;
}

.learnMoreLink {
  text-decoration: none;
}

@media (max-width: 981px) {
  .heroContainer {
    padding: 20px;
  }
  .titleDescription {
    font-size: 25px !important;
    margin-top: 16px !important;
    font-family: 'Poppins', sans-serif;
  }

  .globeContainer {
    width: 100%;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center!important;
  }
}
