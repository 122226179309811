.services {
  padding: 7rem 0 6rem;
  width: 100% !important;
  background-color: #0e4b7a;
  font-family: "Poppins", sans-serif;
  background-image: url("../../../Assets/31.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
}
.service {
  margin: 1.5rem 0;
}

.middleService {
  margin: 1.5rem 0;
  padding: 1rem;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
}

.service-card {
  box-shadow: 0 0px 60px 0 rgb(0 0 0 / 2%);
  padding: 1rem;
  border-radius: 0.5rem;
  position: relative;
  background: white;
  text-align: center;
  z-index: 1;
  cursor: pointer;
  height: 250px;
  overflow: hidden;
}

.service-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: transform 300ms ease-out;
  transform: scaleY(0);
  border-radius: 0.5rem;
  background-color: #e2def9;
}
.serviceImg {
  height: 90px;
  background-color: #cbdade;
  padding: 0.2rem 0.5rem;
  border-radius: 0.5rem;
  transition: 0.4s;
}

.serviceDes {
  color: #666;
  font-weight: 500;
  line-height: 1.5;
  font-family: sans-serif;
  font-size: 1rem;
  margin-bottom: 0;
}
.serviceName {
  font-weight: 700;
  font-size: 20px;
  margin-top: 20px;
}

.bookingBox {
  display: none;
  transition: 2s;
}
.bookingBtn {
  border-radius: 1.8rem;
  font-weight: 500;
  font-size: 1rem;
  outline: none;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  background: #7355f7;
  margin-top: 0.3rem;
}

.servicePrice {
  color: #755bff;
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 0;
}

.bookingBtn:hover {
  background: #4b24f5;
}

@media (max-width: 981px) {
  .service-card {
    height: unset;
  }
  .serviceName {
    font-size: 19px;
  }
  .serviceDes {
    font-size: 15px;
  }
}

.arrowIcon {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 10px;
  bottom: 10px;
}
