.processesContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 40px;
}

.processItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 16px;
  line-height: 1.4;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

.processTitle {
  margin-top: 10px;
  font-size: 22px;
  font-family: "Poppins", sans-serif;
}

.processDescription {
  color: #5e5e5e;
  font-family: "Poppins", sans-serif;
  flex: 1;
  padding: 4px;
}

.processImg {
  width: 50px;
  height: 50px;
}

@media (max-width: 981px) {
  .processesContainer {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
}
