.about {
  max-width: 1320px;
  margin: 0 auto;
}

.headerTitle {
  margin-bottom: 10px;
  font-size: 40px;
  font-weight: 800;
  font-family: sans-serif !important;
}

.headerContent {
  font-size: 18px;
  line-height: 1.5;
  color: #5e5e5e;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  margin: 0.5rem 0 1rem;
}

.coreValuesContainer {
  margin-top: 40px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.coreValuesTitle {
  text-align: center;
  font-size: 30px;
  font-weight: 800;
  font-family: sans-serif !important;
  color: #1876d2;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
}

.coreValuesDescription {
  font-size: 18px;
  line-height: 1.5;
  color: #5e5e5e;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  margin: 0.5rem 0 1rem;
  text-align: center;
}

.coreValues {
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.coreItem {
  width: 500px;
  height: 400px;
  background-color: #0e4b7a;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  border-radius: 8px;
}

.coreItemImage {
  width: 70px;
  height: 70px;
  object-fit: cover;
}

.coreName {
  color: white;
  margin-top: 20px;
  font-family: "Poppins", sans-serif;
}

.coreDescription {
  color: white;
  padding-right: 20px;
  padding-left: 20px;
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

.aboutUsText {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #3b3b3b;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
}

.img-fluid {
  object-fit: contain;
}
@media (max-width: 981px) {
  .about {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
