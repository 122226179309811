.notFoundContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f9fafc;
}

.notFoundImg {
    width: 500px;
    height: 500px;
}

@media (max-width: 981px) { 
    .notFoundContainer { 
        width: 100%;
    }
    .notFoundImg { 
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}