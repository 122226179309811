.privacyPolicyContainer {
  max-width: 1320px;
  margin: 0 auto;
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
}

.privacyPolicyContent {
  padding: 30px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border-radius: 8px;
  width: 70%;
  margin-top: 20px;
}

.privacyPolicyDesc {
  font-size: 17px;
  line-height: 1.5;
  color: #5e5e5e;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

.privacyPolicySubTitle {
  font-size: 24px;
  font-weight: 700;
  font-family: sans-serif !important;
  color: #1876d2;
  font-family: "Poppins", sans-serif;
  text-align: start;
}

@media (max-width: 981px) {
  .privacyPolicyContent {
    width: 90%;
  }
}
