.technologiesContainer {
  background-color: #f4f4f5;
  border-radius: 8px;
  padding: 40px;
  width: 100%;
}

.technologieCardItem {
    padding: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.technologies {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.technologiesTitle {
    font-size: 28px;
    font-weight: 700;
    font-family: sans-serif !important;
    color: #030303;
    font-family: "Poppins", sans-serif;
    text-align: center;
    margin-top: 20px;
}

.techImg {
    width: 50px;
    height: 50px;
    border-radius: 8px;
}

.technologieName {
    color: #000000;
    font-family: "Poppins", sans-serif;
    font-size: 22px;
}

@media (max-width: 981px) {
    .technologies { 
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}