.ourProcessContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
  max-width: 1320px;
  overflow: hidden;
  margin-top: 30px;
}

.descriptionContainer {
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.descriptionText {
  font-weight: 400px;
  font-family: 'Poppins', sans-serif;
}


@media (max-width: 981px) {
  .descriptionText {
    padding: 20px;
    text-align: start;
  }
}