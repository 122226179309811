.contactContainer {
  background-color: rgb(243, 250, 255);
}

#contact {
  padding: 0 0 6rem;
  overflow: hidden;
  max-width: 1320px;
  margin: 0 auto;
  background-color: rgb(243, 250, 255);
}
.sectionTitle {
  margin: 0.5rem 0 3rem;
  font-size: 2rem;
  font-weight: 700;
  position: relative;
  display: inline-block;
  padding-bottom: 0.6rem;
}
.sectionTitle::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100px;
  border-radius: 70px;
  height: 4px;
  background: #027d7c;
}

.contactForm {
  padding: 1rem;
}
.contactForm input,
.contactForm textarea {
  width: 100%;
  margin: 0.5rem 0;
  padding: 1rem 0.8rem;
  border-radius: 0.3rem;
  border: 1px solid #bebebe;
  color: #000;
}
.contactForm input:focus,
.contactForm textarea:focus {
  outline: none !important;
  border: 1px solid #027d7c;
  background-color: rgb(243, 240, 255);
}
.contactForm textarea {
  height: 120px;
}

.contactTitle {
  text-align: center;
  color: #1876d2;
  font-size: 34px;
  margin-top: 20px;
}

.contactDescription {
  text-align: center;
  font-size: 18px;
  line-height: 1.5;
  color: #5e5e5e;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  margin: 0.5rem 0 1rem;
  text-align: center;
}

.contactBoxes {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 20px;
}

.boxItem {
  border: 1px solid #dedfe1;
  padding: 20px;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 8px;
}

.boxItemTitle {
  font-size: 20px;
  margin-top: 10px;
}

.contactBoxImg {
  width: 50px;
  height: 50px;
}

.socialMediaContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #d0e1f1;
  margin-top: 40px;
  border-radius: 8px;
}

.socialMediaTitle {
  font-size: 28px;
  color: #00487a;
  margin-top: 20px;
}

.socialMediaDescription {
  text-align: center;
  font-size: 16px;
  line-height: 1.5;
  color: #00487a;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  margin: 0.5rem 0 1rem;
  text-align: center;
}

.socialMediaIcons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  gap: 20px;
  padding-bottom: 20px;
}

.socialMediaIcon {
  cursor: pointer;
  width: 50px;
  height: 50px;
}
.boxValue {
  font-size: 18px;
  line-height: 1.5;
  color: #5e5e5e;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

.inputText {
  background-color: white;
}


@media (max-width: 981px) { 
  .contactTitle {
    margin-top: 20px;
  }
  .contactDescription {
    padding: 10px;
  }
  .contactBoxes {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  .boxItem {
    width: 100%;
  }
  .socialMediaTitle {
    text-align: center;
  }
  .socialMediaDescription {
    text-align: center;
    padding: 10px;
  }
}