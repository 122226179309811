.whyChooseUsContainer {
  max-width: 1320px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5rem;
  margin-bottom: 5rem;
  gap: 40px;
  padding: 20px;
}

.whyChooseUsDescriptionContainer {
  width: 50%;
  padding: 20px;
  border-radius: 8px;
}

.whyChooseUsImgContainer {
  width: 50%;
}

.whyChooseUsImg {
  width: 70%;
  height: 600px;
  object-fit: cover;
  border-top-left-radius: 120px;
  border-bottom-right-radius: 120px;
}

.whyChooseUsTitle {
  font-size: 34px;
  font-weight: 700;
  font-family: sans-serif !important;
  color: #1876d2;
  text-align: start;
}

.whyChooseSubDesc {
  font-size: 22px;
  line-height: 1.5;
  color: #5e5e5e;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  margin: 0.5rem 0 1rem;
  text-align: start;
}

.whyChooseDesc {
  font-size: 22px;
  line-height: 1.5;
  color: #5e5e5e;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  margin: 0.5rem 0 1rem;
  text-align: start;
  margin-top: 30px;
}

@media (max-width: 981px) {
  .whyChooseUsContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }

  .whyChooseUsDescriptionContainer {
    width: 100%;
    padding: unset;
  }

  .whyChooseUsTitle {
    text-align: center !important;
    font-size: 30px;
  }

  .whyChooseUsImgContainer {
    width: 100%;
  }

  .whyChooseSubDesc {
    text-align: center !important;
    font-size: 20px;
    width: 100%;
  }

  .whyChooseUsImg {
    width: 100%;
    height: 500px;
  }

  .whyChooseDesc {
    width: 100%;
    padding: unset;
    font-size: 20px;
    text-align: center;
  }
}
