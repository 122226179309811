.serviceDetailsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(243, 250, 255);
}

.serviceContent {
  max-width: 1320px;
  margin: 0 auto;
  margin-top: 2rem;
}

.serviceHeaderContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.serviceHeaderItem1 {
  width: 50%;
}

.serviceHeaderItem2 {
  width: 50%;
  display: flex;
  gap: 20px;
  height: 50%;
}

.collageColumns {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.collageImage2 {
  width: 300px;
  border-radius: 8px;
  object-fit: contain;
  height: auto;
}

.introContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.serviceTitle {
  font-size: 30px;
  font-weight: 700;
  font-family: sans-serif !important;
  color: darkcyan;
  font-family: "Poppins", sans-serif;
  text-align: start;
  width: 600px;
}

.serviceTitleExtra {
  font-size: 30px;
  font-weight: 700;
  font-family: sans-serif !important;
  color: #000000;
  font-family: "Poppins", sans-serif;
  text-align: center;
}

.serviceDescription {
  font-size: 18px;
  line-height: 1.5;
  color: #5e5e5e;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  margin: 0.5rem 0 1rem;
  text-align: start;
  width: 80%;
  margin-top: 20px;
}

.serviceImage {
  width: 50%;
  border-radius: 8px;
  height: 500px;
}

.extraServiceImage {
  width: 100%;
  height: 400px;
  object-fit: contain;
}

.serviceDescriptionContainer {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  justify-content: center;
}

.additionalInfosContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px;
  background-color: #d0e1f1;
  margin-top: 40px;
  border-radius: 8px;
}

.extraAdditionalInfosContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.serviceTitleWhy {
  font-size: 28px;
  font-weight: 700;
  font-family: sans-serif !important;
  color: #1876d2;
  font-family: "Poppins", sans-serif;
  text-align: center;
}

.shortDescription {
  font-size: 18px;
  line-height: 1.5;
  color: #5e5e5e;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

.steps {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 30px;
  padding: 20px;
}

.stepItem {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  width: 50%;
}

.stepDescription {
  font-size: 18px;
  line-height: 1.5;
  color: #5e5e5e;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  text-align: start;
  width: 95%;
}

.checkIcon {
  width: 50px;
  height: 50px;
}

.paginationContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
}

.activePage {
  color: #1876d2;
}
.defaultPage {
  color: #5e5e5e;
}

.extraDescriptionContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 30px;
  background-color: #d0e1f1;
  border-radius: 8px;
  padding: 20px;
}

.extraDescription {
  font-size: 18px;
  line-height: 1.5;
  color: #5e5e5e;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

.extraImg {
  width: 50%;
  border-radius: 8px;
}

.otherServices {
  font-size: 28px;
  font-weight: 700;
  font-family: sans-serif !important;
  color: #1876d2;
  font-family: "Poppins", sans-serif;
  text-align: center;
  margin-top: 20px;
}

.lastSection {
  background-color: #d0e1f1;
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 8px;
}

.ourTeamTitle {
  font-size: 28px;
  font-weight: 700;
  font-family: sans-serif !important;
  color: #1876d2;
  font-family: "Poppins", sans-serif;
  text-align: start;
  margin-top: 20px;
}

.teamSection {
  background-color: #d0e1f1;
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
}

.teamItem {
  width: 100%;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.teamPositionName {
  text-align: center;
  margin-top: 10px;
}

.teamIcon {
  width: 60px;
  height: 60px;
}

.teamTechnologies {
  display: flex; 
  flex-direction: column;
  width: 100%;
}

@media (max-width: 981px) {
  .additionalInfosContainer {
    padding: 10px;
  }
  .steps {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: unset;
  }
  .stepItem {
    width: 100%;
  }
  .serviceImage {
    width: 100%;
    height: unset;
  }
  .checkIcon {
    width: 50px;
    height: 50px;
  }

  .serviceTitle {
    font-size: 28px;
    text-align: center;
    width: unset;
    padding: 10px;
  }

  .content {
    padding: 20px;
  }

  .lastSection {
    padding: 20px;
  }

  .serviceHeaderContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .serviceHeaderItem1 {
    width: 100%;
  }

  .serviceHeaderItem2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .serviceDescription {
    text-align: center;
    width: 100%;
    padding: 10px;
  }

  .serviceImage,
  .collageImage2 {
    width: 300px;
  }

  .extraImg {
    width: 100%;
    border-radius: 8px;
  }

  .serviceContent {
    margin-top: unset;
  }

  .teamSection {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .teamItem {
    width: 100% !important;
  }
  
  .teamIcon {
    margin-top: 10px;
  }

  .ourTeamTitle { 
    text-align: center;
  }
}
