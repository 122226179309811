.navStyle {
  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
  background: white;
  position: fixed !important;
  width: 100%;
  z-index: 99 !important;
  font-family: "Poppins", sans-serif;
}

.logoImg {
  width: 280px;
}

.navDefault {
  transition: 0.5s !important;
  width: 100%;
}

.mainNav .nav-link {
  color: #1c0f50 !important;
  margin: 0.5rem 1.2rem !important;
  font-weight: 500 !important;
  transition: 0.3s;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
}

.mainNav .nav-link:after {
  content: "";
  display: block;
  margin: auto;
  height: 3px;
  width: 0px;
  background: transparent;
  transition: width 0.5s ease, background-color 0.5s ease;
}

.mainNav .nav-link:hover:after {
  width: 100%;
  background: #027d7c;
}

.navBrn {
  font-weight: 700;
  font-size: 1.5rem;
}
.brnIcon,
.navHighlight {
  color: #027d7c;
}
.brnIcon {
  font-weight: 800;
  font-size: 1.6rem;
}

.loginBtn {
  outline: none;
  border: none;
  border-radius: 50px;
  font-weight: 500;
  color: #fff;
  background: #027d7c;
  padding: 0.5rem 1.5rem;
  margin: 0.5rem 1rem;
  transition: 0.4s;
}
.loginBtn:hover {
  background: #027d7c;
}
.navActiveClass {
  color: "red";
  font-weight: 900;
}

@media (max-width: 981px) {
  .navDefault {
    padding: 0.1rem 0.5rem !important;
    width: 100%;
    z-index: 99 !important;
    transition: 0.5s !important;
    text-align: center;
  }
  .navStyle {
    width: 100%;
    z-index: 99 !important;
    transition: 0.5s !important;
    text-align: center;
    padding: 0.2rem 0.5rem !important;
    transition: 0.5s !important;
  }
  .logoImg {
    width: 240px;
  }
  .dropDownContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
