.header {
  background: white;
  background-image: url("../../../Assets/bg12.jpg");
  background-repeat: no-repeat;
  background-position: right;
  width: 100%;
  overflow: hidden;
}

.titleArea {
  padding: 1rem;
}

.miniTitle {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #ffffff;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
}

.branBtn {
  background: #027d7c;
  padding: 18px 35px;
  display: inline-block;
  border-radius: 50px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  text-transform: uppercase;
  border: none;
  color: #fff;
  transition: 0.4s;
}
.branBtn:hover {
  color: #fff;
  background-color: #027d7c;
}
.headerHighlight {
  color: #027d7c;
  font-size: 40px;
}

@media (max-width: 981px) {
  .header {
    overflow: visible;
    height: 100%;
  }
  .headerTitle {
    margin-bottom: 10px;
    font-size: 40px;
    font-weight: 700;
    font-family: sans-serif !important;
  }
}
